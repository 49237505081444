import React, { useEffect } from 'react'
import './Hero.scss'
import { Link } from 'gatsby'
import { students, schools } from '../../api/stats'
import { range } from 'lodash'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default () => {
  // NOTE[ZAP]: Responses Collected is taking too long, and we got no time to support this rn, so we're commenting it out
  // and we are going to hard code values.
  // useEffect(() => {
  //   let steps = []
  //   let currentIndex = 0
  //   let stepInterval
  //   var HOST = 'wss://pulse-websocket-server.herokuapp.com/'
  //   var ws = new WebSocket(HOST)
  //   const numberWithCommas = x => {
  //     return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  //   }
  //   students().then(studentCount => {
  //     document.querySelector('#num-students').innerHTML = numberWithCommas(studentCount)
  //   })
  //   schools().then(campusCount => {
  //     document.querySelector('#campuses').innerHTML = numberWithCommas(campusCount)
  //   })
  //   ws.onmessage = function (event) {
  //     if (!steps.length) {
  //       steps = [event.data]
  //       document.querySelector('#responses-collected').innerHTML = numberWithCommas(event.data)
  //     } else {
  //       const currentNumber = steps[currentIndex]
  //       steps = range(currentNumber, event.data)
  //       currentIndex = 0
  //       clearInterval(stepInterval)
  //       stepInterval = setInterval(() => {
  //         if (currentIndex < steps.length - 1) {
  //           currentIndex += 1
  //           document.querySelector('#responses-collected').innerHTML = numberWithCommas(
  //             steps[currentIndex]
  //           )
  //         }
  //       }, 2500 / steps.length)
  //     }
  //   }
  // }, [])

  return (
    <header className="hero">
      <div className="thanks-safari"></div>
      {/* Turn into component */}
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-1 has-text-weight-bold">
            The Voice of
            <br />
            College Students
          </h1>
          <hr className="has-background-success opacity03" />
          <p>
            Data-driven marketing and custom research solutions to help businesses and nonprofit
            organizations better understand and connect with American college students.
          </p>
          <div className="button-box">
            <AnchorLink to="/#contactus">
              <button className="is-success button is-large has-padding-left-5 has-padding-right-5 has-text-weight-bold">
                <span>GET IN TOUCH</span>
              </button>
            </AnchorLink>
            <a className="app-button" href="https://cpulse.link/psl/67dc766a6985f7ba4907dfbb">
              STUDENT SIGN UP
            </a>
          </div>

          <div className="stats-container">
            {/* Turn into component */}
            <div className="is-flex block has-padding-bottom-4 ">
              <div className="has-padding-right-4 has-margin-right-4">
                <img className="call-icon" alt="icon" src="/icons/call_responses.svg" />
              </div>
              <div>
                <h3
                  className="has-text-success title is-4 has-text-weight-bold"
                  id="responses-collected"
                >
                  {/* ... */}
                  130,000,000+
                </h3>
                <h5 className="subtitle is-6">Responses Collected</h5>
              </div>
            </div>
            <div className="is-flex block has-padding-bottom-4 has-padding-top-3">
              <div className="has-padding-right-4 has-margin-right-4">
                <img className="call-icon" alt="icon" src="/icons/call_student.svg" />
              </div>
              <div>
                <h3 className="has-text-success title is-4 has-text-weight-bold" id="num-students">
                  {/* ... */}
                  800,000+
                </h3>
                <h5 className="subtitle is-6">Students Signed Up</h5>
              </div>
            </div>
            <div className="is-flex block has-padding-top-3">
              <div className="has-padding-right-4 has-margin-right-4">
                <img className="call-icon" alt="icon" src="/icons/call_school.svg" />
              </div>
              <div>
                <h3 className="has-text-success title is-4 has-text-weight-bold" id="campuses">
                  {/* ... */}
                  1500+
                </h3>
                <h5 className="subtitle is-6">College Campuses</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
